/* eslint-disable no-prototype-builtins */
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ShowToastrService } from './../show-toastr/show-toastr.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { addDays, differenceInDays, endOfDay, format, getDay, isBefore, startOfDay, toDate } from 'date-fns';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { enUS, es } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  urlImage = environment.apiUrl;
  showErrorState = false;
  language: string;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public sanitizer: DomSanitizer,
    private showToastr: ShowToastrService,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    public translate: TranslateService,
    private router: Router
  ) {
    this.language = this.translateService.currentLang || 'es';
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribeAll))
      .subscribe((data: any) => this.language = data.lang);
  }

  static getDateAndTime(date: string, hour?): Date {
    const elem: string[] = date.split('-');
    const d = new Date();
    d.setFullYear(Number(elem[0]), Number(elem[1]) - 1);
    d.setDate(Number(elem[2]));

    if (hour) {
      const elemHour = hour.split(/[/ :]/);
      d.setHours(elemHour[0], elemHour[1], 0);
    }

    return d;
  }
  static buildInitialDate(dataDate) {
    const date = UtilsService.getOnlyDate(dataDate);
    const elem = UtilsService.getDateFromOnlyDateString(date);
    elem.setHours(0, 0, 0);
    elem.setUTCHours(0, 0, 0, 0);

    return elem;
  }

  static buildEndDate(dataDate) {
    const date = UtilsService.getOnlyDate(dataDate);
    const elem = UtilsService.getDateFromOnlyDateString(date);
    elem.setHours(23, 59, 59);
    elem.setUTCHours(23, 59, 59, 0);
    return elem;
  }
  static getOnlyDate(date: string): string {
    if (date) {
      const dateStrings = date.split('T');
      if (dateStrings.length === 2) {
        return dateStrings[0];
      }
    }

    return '-';
  }

  static getYesterday(date: string | Date): Date {
    const d = new Date(date);
    d.setDate(d.getDate() - 1);
    d.setHours(23);
    d.setMinutes(59, 59, 999);

    return d;
  }

  static getDateFromOnlyDateString(date): Date {
    const elem = date?.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1, elem[2]);
    d.setHours(0);
    d.setMinutes(0, 0, 0);
    return d;
  }

  static getFormArray(form: any, field: string) {
    return form.get(field) as UntypedFormArray;
  }

  static getControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return (
      Object.keys(formGroup).find((name) => control === formGroup[name]) || null
    );
  }

  static resolvePropertyByPath(obj: any, path: string[]) {
    return path.reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  public createDate(date: string, time?: string): Date {
    let dateArray: string[] = [];
    let arrayDate: string[];

    if (date.split('T').length > 1) {
      dateArray = date.split('T');
      arrayDate = dateArray[0].split('-');
    } else {
      arrayDate = date.split('-');
    }

    let arrayTime = ['0', '00'];
    if (time) {
      arrayTime = time.split(':');
    }

    const responseDate = new Date(parseInt(arrayDate[0]), parseInt(arrayDate[1]) - 1, parseInt(arrayDate[2]));
    responseDate.setHours(parseInt(arrayTime[0]));
    responseDate.setMinutes(parseInt(arrayTime[1]));

    return responseDate;
  }

  public createDateString(date: string | Date): string {
    let dateArray: string[] = [];
    let arrayDate: string[];

    if (typeof date !== 'string') {
      date = date.toISOString();
    }

    if (date.split('T').length > 1) {
      dateArray = date.split('T');
      arrayDate = dateArray[0].split('-');
    } else {
      arrayDate = date.split('-');
    }

    return arrayDate[0] + '-' + arrayDate[1] + '-' + arrayDate[2];
  }

  setValidators(form, field, validators?) {
    form.get(field).clearValidators();
    if (validators) {
      form.get(field).setValidators(validators);
    } else {
      form.get(field).setValidators([]);
    }

    form.get(field).updateValueAndValidity();
  }

  public getControls(form: any, field: string) {
    return form.get(field) as UntypedFormArray;
  }

  public keyPressAlphaSpace(event): boolean {
    const inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z 0-9,.]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public keyPressDecimalNumbers(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 46 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public keyPressNumbers(key): boolean {
    return key?.charCode === 8 || key?.charCode === 0 ? null : key?.charCode >= 48 && key?.charCode <= 57;
  }

  public getUrlImages(): string {
    return environment.apiUrl;
  }

  public getSafeImage(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public safeToDom(data: string) {
    return this.sanitizer.bypassSecurityTrustHtml(data);
  }

  public publicSetDescription(data: any, language) {
    return this.safeToDom(this.parserLanguage(data, language));
  }

  errorHandle(error) {
    if (this.showErrorState) {
      return;
    }

    this.showErrorState = true;

    let msg;
    if (error?.errors?.length > 0) {
      msg = error?.errors?.map((item: any) => (item?.field || '') + ' ' + (item?.title || item?.message + ' '));
    } else if (error?.error?.errors) {
      msg = error?.error?.errors?.map((item: any) => (item.field || '') + ' ' + (item?.title || item?.message + ' '));
    } else if (error?.error?.length > 0 && Array.isArray(error?.error)) {
      msg = error?.error?.map((item: any) => (item.field || '') + ' ' + (item?.title || item?.message + ' '));
    } else {
      msg = error?.error?.message || error?.title;
    }

    this.showToastr
      .showInfo(msg, 'Error', 5000)
      .toastRef.afterClosed()
      .subscribe(() => {
        this.showErrorState = false;
      });
  }

  parserLanguage(item, language) {
    if (!item) {
      return '-';
    }

    if (item[language] && item[language].length) {
      return item[language];
    } else if (item.en && item.en.length) {
      return item.en;
    } else {
      return item.es;
    }
  }

  public isObjectEquals(x, y): boolean {
    if (x === y) return true;

    if (!(x instanceof Object) || !(y instanceof Object)) return false;

    if (x.constructor !== y.constructor) return false;

    for (const p in x) {
      if (!x.hasOwnProperty(p)) continue;

      if (!y.hasOwnProperty(p)) return false;

      if (x[p] === y[p]) continue;

      if (typeof x[p] !== 'object') return false;

      if (!this.isObjectEquals(x[p], y[p])) return false;
    }
    for (const p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    return true;
  }

  public parseExperienceDate(stringDate: string): Date {
    if (!stringDate) {
      return new Date();
    }

    const dates = stringDate.split('-');

    return new Date(Number(dates[0]), Number(dates[1]) - 1, Number(dates[2]));
  }

  public getControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return (
      Object.keys(formGroup).find((name) => control === formGroup[name]) || null
    );
  }

  /**
   * Devuelve la estructura correcta de personas en el módulo experiencias
   * @param capacity Este valor es devuelto por el filtro de personas
   * @return Devuelve el texto concatenado de las personas
   */
  capacityText(capacity: any): any {
    let capacityText;
    const adultsCount = capacity.adultQuantity;
    const childrenCount = capacity.childrenQuantity;

    if (adultsCount > 1) {
      capacityText = `${adultsCount.toString()} ${this.translateService.instant('Adultos')}`;
    } else {
      capacityText = `${adultsCount.toString()} ${this.translateService.instant('Adulto')}`;
    }

    if (childrenCount > 1) {
      capacityText += `, ${childrenCount.toString()} ${this.translateService.instant('Niños')}`;
    } else if (childrenCount === 1) {
      capacityText += `, ${childrenCount.toString()} ${this.translateService.instant('Niño')}`;
    }

    return capacityText;
  }

  /**
   * Devuelve la estructura correcta de personas en el módulo hoteles
   * @param capacity Este valor es devuelto por el filtro de personas
   * @param showRooms Variable para mostrar o no las habitaciónes
   * @return Devuelve el texto concatenado de las personas y habitaciónes
   */
  public capacityPackageText(capacity: any = {}, showRooms: boolean = true): any {
    let capacityText: string = '';
    let adultsCount: number = 0;
    let childrenCount: number = 0;
    const searchCapacity = [];

    if (capacity?.rooms) {
      capacity?.rooms?.forEach((item: any) => {
        const room = {
          adults: item.adults,
          children: [] as number[]
        };

        item?.childrenAges?.forEach((children: any) => {
          room.children.push(children.age);
        });

        adultsCount += item?.adults;
        childrenCount += item?.children;
        searchCapacity.push(room);
      });

      if (adultsCount && typeof adultsCount === 'number') {
        if (adultsCount > 1) {
          capacityText = `${adultsCount.toString()} ${this.translate.instant('Adultos')}`;
        } else {
          capacityText = `${adultsCount.toString()} ${this.translate.instant('Adulto')}`;
        }
      }

      if (childrenCount && typeof childrenCount === 'number') {
        if (childrenCount > 1) {
          capacityText += `, ${childrenCount.toString()} ${this.translate.instant('Niños')}`;
        } else if (childrenCount === 1) {
          capacityText += `, ${childrenCount.toString()} ${this.translate.instant('Niño')}`;
        }
      }
    }

    let roomString = '';
    if (showRooms && capacity?.rooms) {
      roomString = `, ${capacity?.rooms.length} `;
      if (capacity.rooms.length > 1) {
        roomString += this.translate.instant('Habitaciones');
      } else {
        roomString += this.translate.instant('Habitación');
      }
    }

    return capacityText + roomString;
  }

  /**
   * Actualiza los parámetros de la url
   * @param filters Refiere a los parámetros actuales de la url
   * @param url Valor de la url a actualizar
   * @param data Parámetros a actualizar o agregar
   * @param refresh Decide si refrescar o no la ruta
   */
  public updateFilter(url: string, filters: any, data?: any, refresh?: boolean): void {
    let filter = Object.assign({}, filters);

    if (data) {
      filter = Object.assign(filter, data);
    }

    if (refresh) {
      filter.refresh = new Date().getTime();
    }

    this.router.navigate([url], {
      queryParams: { filters: JSON.stringify(filter) }
    }).then();
  }

  /**
   * Construye una cadena de texto con la fecha en el formato 'LL'
   * @param date La fecha a formatear
   * @param f El formato de la fecha de salida
   * @param lang
   * @return Devuelve una cadena de texto con una fecha formateada y traducida Ej: '1ro de enero del 2021' o 'January 1st, 2021'
   */
  public parseDate(date: Date, f: string, lang?: string): string {
    if (!date) {
      return '';
    }

    if (lang) {
      if (lang === 'es') {
        return format(new Date(date), f, { locale: es });
      } else {
        return format(new Date(date), f, { locale: enUS });
      }
    } else {
      if (this.translate?.currentLang) {
        if (this.translate?.currentLang === 'es') {
          return format(new Date(date), f, { locale: es });
        } else {
          return format(new Date(date), f, { locale: enUS });
        }
      } else {
        return format(new Date(date), f, { locale: es });
      }
    }
  }

  /**
   * Construye una cadena de texto con la fecha en el formato 'LL'
   * @param days La fecha a formatear
   * @param date
   * @return Devuelve la fecha con 'days' días a partir de la fecha actual
   */
  public getDate(days: number, date?: Date): Date {
    if (!date) {
      return addDays(new Date(), days);
    }

    return addDays(new Date(date), days);
  }

  public addDays(days: number, date?: Date): string {
    if (!date) {
      return addDays(new Date(), days).toISOString();
    }

    return addDays(new Date(date), days).toISOString();
  }

  public getStartOf(date: Date, f?: string): string {
    if (!f) {
      return startOfDay(new Date(date)).toISOString();
    }

    if (this.translate?.currentLang === 'es') {
      return format(startOfDay(new Date(date)), f, { locale: es });
    } else {
      return format(startOfDay(new Date(date)), f, { locale: enUS });
    }
  }

  public getEndOf(date: Date, f?: string): string {
    if (!f) {
      return endOfDay(new Date(date)).toISOString();
    }

    if (this.translate?.currentLang === 'es') {
      return format(endOfDay(new Date(date)), f, { locale: es });
    } else {
      return format(endOfDay(new Date(date)), f, { locale: enUS });
    }
  }

  public getDiffDate(startDate: Date | string, endDate: Date | string): number {
    return differenceInDays(new Date(endDate), new Date(startDate));
  }

  public getWeekDaysBetweenRangeDate(startDate: Date, endDate: Date): number[] {
    if (!startDate || !endDate) {
      return [];
    }

    const days: number[] = [];
    for (let m = toDate(startDate); isBefore(m, toDate(endDate)); m = addDays(m, 1)) {
      days.push(getDay(m));
    }
    days.push(getDay(endDate));

    return Array.from<number>(new Set(days));
  }
}
